<template>
  <section class="sponsor-uploader">
    <p><i>Section ID: {{ sectionID }}</i></p>

    <v-layout row>
      <v-flex class="status-wrapper">
        <p v-if="sponsorData.active" class="status status--active">Enabled</p>
        <p v-if="!sponsorData.active" class="status status--inactive">Disabled</p>
        <v-switch
          :true-value=1
          :false-value=0
          v-model="sponsorData.active">
        </v-switch>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex>
        <label id="name">Name</label>
        <v-text-field
          id="name"
          v-model="sponsorData.name"
          solo
          flat
        ></v-text-field>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex mb-3>
        <ImageUploader :title="'Sponsor image'" :widthOptionEnabled="false" :mediaLibraryID="sponsorData.mediaLibraryID" @update="updateImage($event)" :desc="'<p>Recommended size: 160px width / 80px height</p>'"/>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex>
        <label id="supportingText">Supporting Text</label>
        <v-text-field
          id="supportingText"
          v-model="sponsorData.supportingText"
          solo
          flat
        ></v-text-field>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex>
        <label id="link">Url</label>
        <v-text-field
          id="link"
          v-model="sponsorData.link"
          solo
          flat
        ></v-text-field>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex>
        <label id="campaignReference">Campaign Reference</label>
        <v-text-field
          id="campaignReference"
          v-model="sponsorData.campaignReference"
          solo
          flat
        ></v-text-field>
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex>
        <v-checkbox
          v-model="sponsorData.childFriendly"
          :label="`Child Friendly`"
        ></v-checkbox>
      </v-flex>
    </v-layout>

    <p v-if="updating">Updating</p>
    <template v-else>
      <v-btn v-if="sponsorLoaded" type="button" @click="updateSponsor()" color="accent">Update</v-btn>
      <v-btn v-else type="button" @click="addSponsor()" color="accent">Add</v-btn>
    </template>
  </section>
</template>

<script>
import axios from "axios"
import ImageUploader from "@/components/Library/ImageUploader"

export default {
  name: "SponsorUploader",

  props: {
    sectionID: {
      type: String
    }
  },

  components: {
    ImageUploader
  },

  data: () => ({
    imageDomain: process.env.VUE_APP_IMAGEURL,
    api: `${process.env.VUE_APP_SPONSORS_WEB}/sections`,
    sponsorLoaded: false,
    sponsorData: {
      sectionID: "",
      sectionSponsorID: "",
      name: "",
      mediaLibraryID: "",
      imageKey: "",
      supportingText: "",
      link: "",
      campaignReference: "",
      childFriendly: true,
      active: 0
    },
    updating: false
  }),

  mounted() {
    this.sponsorData.sectionID = this.sectionID
    this.fetchSponsor(this.sectionID)
  },

  methods: {
    fetchSponsor(sectionID) {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/sponsors/getsingle?sectionID=${sectionID}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Sponsors:", response);

          if (response.data.body.length) {
            this.sponsorData = response.data.body[0]
            this.sponsorLoaded = true
          }
        })
        .catch(e => {
          console.error("Error loading sponsors", e);
        });
        this.$store.commit("completeLoading");
      })
    },

    addSponsor() {
      this.sponsorData.childFriendly = this.sponsorData.childFriendly ? 1 : 0
      this.updating = true

      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/sponsors/addnew`,
          headers: { "Authorization": jwt },
          data: this.sponsorData
        })
        .then(response => {
          console.log("Response:", response);

          if (response.data.success) {
            this.sponsorLoaded = true
            // this.status = 'saved';
          } else {
            // this.status = 'needsSaving'
            alert(response.data.message)
          }
          this.$store.commit("completeLoading");
          this.updating = false
        })
        .catch(e => {
          console.error("Error updating sponsor", e);
          this.$store.commit("completeLoading");
          // this.errorMessage = 'Problem updating sponsor [error: ]';
          this.updating = false
        });
      });
    },

    updateSponsor() {
      this.sponsorData.childFriendly = this.sponsorData.childFriendly ? 1 : 0
      this.updating = true

      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/sponsors/update`,
          headers: { "Authorization": jwt },
          data: this.sponsorData
        })
        .then(response => {
          console.log("Response:", response);

          if (response.data.success) {
            // this.status = 'saved';
          } else {
            // this.status = 'needsSaving'
            alert(response.data.message)
          }

          this.$store.commit("completeLoading");
          this.updating = false
        })
        .catch(e => {
          console.error("Error updating sponsor", e);
          this.$store.commit("completeLoading");
          // this.errorMessage = 'Problem updating sponsor [error: ]';
          this.updating = false
        });
      });
    },

    updateImage(payload) {
      console.log("updateImage", payload)
      this.sponsorData.mediaLibraryID = payload.mediaLibraryID
      this.sponsorData.imageKey = payload.imageKey
    }
  }
}
</script>

<style scoped lang="scss">

  .status-wrapper {
    display: flex;
    align-items: center;
  }

  .status {
    width: 64px;
    margin: 0 24px 4px 0;
    padding: 3px 12px;
    display: inline-block;
    font-size: 10px;
    color: #fff;
    background-color: #E6E8F1;
    border-radius: 4px;
    text-align: center;

    &--active {
      background-color: #5fad41;
    }

    &--inactive {
      background-color: #ff5252;
    }
  }

</style>
