<template>
  <section class="config-selector main-body">
    <MiniHeader :heading="'Footer Sponsors'" :buttonText="'Add Row'" :hasButton="true" @buttonClicked="loadBlankRow()"/>

    <build-notice mode="full" />

    <draggable
      v-if="!refreshing"
      v-model="sponsors"
      @start="drag=true"
      @end="drag=false, updated()">
      <transition-group name="list-complete" tag="ol" class="sponsor-rows">
        <li v-for="row in sponsors" :key="`row-${row.rowID}`" class="sponsors-row draggable-item" :class="{'sponsors-row--draft': !row.published}">
          <div class="sponsors-row__header">
            <div class="sponsors-row__published">
              <v-checkbox  
                v-model="row.published"
                @change="updated()"
              ></v-checkbox>
            </div>

            <h4 class="sponsors-row__subheading subheading">{{ row.adminName }}</h4>

            <v-btn
              class="sponsors-row__edit-row"
              color="accent"
              small
              outline
              flat
              :disabled="false"
              @click="loadRowData(row)"
            >
              Edit row details
            </v-btn>

            <v-btn
              class="sponsors-row__delete-row"
              color="error"
              small
              outline
              flat
              :disabled="false"
              @click="deleteRow(row.rowID)"
            >
              Delete row
            </v-btn>
          </div>

          <div class="sponsors-row__body">
            <FooterRowSponsors :rowID="row.rowID" :sponsors="row.sponsors" @requestUpdate="fetchData()"/>
          </div>
        </li>
      </transition-group>
    </draggable>

    <v-dialog
      class="row-edit"
      v-model="dialog"
      width="580"
      scrollable
      lazy>
      <v-card>
        <v-card-title v-if="rowData.rowID">Edit row - {{ rowData.rowID }}</v-card-title>
        <v-card-title v-else>Add a new row</v-card-title>

        <v-card-text>
          <div>
            <label class="label" for="admin-name">Admin name (required / internal use)</label>
            <v-text-field
              id="admin-name"
              v-model="rowData.adminName"
              solo
              flat
              required
            ></v-text-field>
          </div>

          <div>
            <label class="label" for="title">Title (optional / displays on site)</label>
            <v-text-field
              id="title"
              v-model="rowData.title"
              solo
              flat
            ></v-text-field>
          </div>

          <div>
            <label class="label" for="title-size">Title size</label>
            <v-select
              id="title-size"
              v-bind:items="titleSizeOptions"
              v-model="rowData.titleSize"
              solo
              flat>
            </v-select>
          </div>

          <div>
            <label class="label" for="sponsor-size">Sponsor size</label>
            <v-select
              id="sponsor-size"
              v-bind:items="sponsorSizeOptions"
              v-model="rowData.sponsorSize"
              solo
              flat>
            </v-select>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="rowData.rowID"
            color="accent"
            outline
            flat
            :disabled="rowData.adminName.length < 1 && !rowData.rowID"
            @click="editRow()"
          >
            Edit row
          </v-btn>
          <v-btn
            v-else
            color="accent"
            outline
            flat
            :disabled="rowData.adminName.length < 1"
            @click="addRow()"
          >
            Add row
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <StickyFooter :errorMessage="errorMessage" :status="status" :buttonText="'Update rows'" @buttonClicked="updateRows()" :allowPreview="false"/>
  </section>
</template>

<script>
import axios from "axios"
import draggable from "vuedraggable"
import MiniHeader from "@/components/Library/MiniHeader"
import StickyFooter from "@/components/Library/StickyFooter"
import FooterRowSponsors from "@/components/Sponsors/Subpages/FooterSponsors/FooterRowSponsors"
import BuildNotice from "@/components/Library/BuildNotice/BuildNotice"

export default {
  name: "FooterSponsors",

  components: {
    draggable,
    MiniHeader,
    StickyFooter,
    FooterRowSponsors,
    BuildNotice
  },

  data:() => ({
    api: `${process.env.VUE_APP_SPONSORS_WEB}/footersponsors`,
    sponsors: [],
    refreshing: false,
    rowOrderUpdated: false,
    status: "saved",
    errorMessage: "",
    dialog: false,
    titleSizeOptions: ["h2", "h3", "h4", "h5", "h6"],
    sponsorSizeOptions: ["small", "large"],
    rowData: {
      adminName: "",
      title: "",
      titleSize: "h6",
      sponsorSize: "small"
    }
  }),

  mounted() {
    this.resetRowData()
    this.fetchData()
  },

  beforeRouteLeave (to, from, next) {
    if (this.rowOrderUpdated) {
      const answer = window.confirm("Do you really want to leave? You have unsaved changes to the row structure!")
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },

  methods: {
    updated() {
      this.rowOrderUpdated = true
      this.status = "needsSaving";
    },

    fetchData() {
      this.$store.commit("startLoading");
      this.refreshing = true
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/rows`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Sponsors:", response);
          this.sponsors = response.data.body;
          console.log(this.sponsors);
          this.refreshing = false
        })
        .catch(e => {
          console.error("Error loading sponsors", e);
          this.refreshing = false
        });
        this.$store.commit("completeLoading");
      })
    },

    resetRowData() {
      this.rowData = {
        adminName: "",
        title: "",
        titleSize: "h6",
        sponsorSize: "small"
      }
    },

    addRow() {
      this.$store.commit("startLoading");
      this.status = "saving";
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/rows/addnew`,
          headers: { "Authorization": jwt },
          data: this.rowData
        })
        .then(response => {
          console.log("Response:", response);

          if (response.data.success) {
            this.status = "saved";
            this.resetRowData()
            this.dialog = false
            this.fetchData()
          } else {
            this.status = "needsSaving"
            alert(response.data.message)
          }
        })
        .catch(e => {
          console.error("Error creating row", e);
          this.$store.commit("completeLoading");
          this.errorMessage = "Problem adding row [error: Sodium]";
        });
      })
    },

    loadBlankRow() {
      this.resetRowData()
      this.dialog = true
    },

    loadRowData(rowToEdit) {
      this.rowData = {
        rowID: rowToEdit.rowID,
        adminName: rowToEdit.adminName,
        title: rowToEdit.title,
        titleSize: rowToEdit.titleSize,
        sponsorSize: rowToEdit.sponsorSize
      }

      this.dialog = true
    },

    editRow() {
      this.$store.commit("startLoading");
      this.status = "saving";
      console.log("editRow", this.rowData)
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/rows/update`,
          headers: { "Authorization": jwt },
          data: this.rowData
        })
        .then(response => {
          console.log("Response:", response);

          if (response.data.success) {
            this.status = "saved";
            this.resetRowData()
            this.dialog = false
            this.fetchData()
          } else {
            this.status = "needsSaving"
            alert(response.data.message)
          }
        })
        .catch(e => {
          console.error("Error editing row", e);
          this.$store.commit("completeLoading");
          this.errorMessage = "Problem editing row [error: Sodium]";
        });
      })
    },

    deleteRow(rowID) {
      console.log("rowID", rowID) 
      const answer = window.confirm("Do you really want to delete this row?")
      if (answer) {
        this.$store.commit("startLoading");
        this.status = "saving";

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios
          .delete(`${this.api}/rows/delete?rowID=${rowID}`, { "headers": { "Authorization": jwt }})
          .then(response => {
            console.log("Response:", response);

            if (response.data.success) {
              this.status = "saved";
              this.fetchData()
            } else {
              this.status = "needsSaving"
              alert(response.data.message)
            }
          })
          .catch(e => {
            console.error("Error deleting row", e);
            this.$store.commit("completeLoading");
            this.status = "needsSaving";
            this.errorMessage = "Problem deleting row [error: Sodium]";
          })
        });
      }
    },

    updateRows() {
      this.status = "saving";
      this.$store.commit("startLoading");

      const updateRows = this.sponsors.map((row, index) => {
        return {
          rowID: row.rowID,
          published: row.published ? 1 : 0,
          forcedOrder: index
        }
      });

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/rows/publish`,
          headers: { "Authorization": jwt },
          data: updateRows
        })
        .then(response => {
          console.log("Response:", response);

          if (response.data.success) {
            this.status = "saved";
            this.fetchData()
            this.rowOrderUpdated = false
          } else {
            this.status = "needsSaving"
            alert(response.data.message)
          }
        })
        .catch(e => {
          console.error("Error bulk updating rows", e);
          this.errorMessage = "Problem updating rows [error: Sodium]";
        });
      })

      this.$store.commit("completeLoading");
    }
  }
}
</script>

<style scoped lang="scss">
  .sponsors-row {
    margin: 8px auto;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #ccc;

    &--draft {
      opacity: 0.6;
    }

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &__published {
      margin: 0 16px 0 0;
    }

    &__subheading {
      margin: 0 16px 0 0;
    }

    &__edit-row {
      margin: 0 0 0 auto;
    }
  }

  .list-complete-enter, .list-complete-leave-to {
    opacity: 0;
  }

  .list-complete-leave-active {
    position: absolute;
    width: calc(100% - (var(--body-gutter) * 2));
  }

  .draggable-item {
    transition: all 0.5s, opacity 0.2s;
  }
</style>
