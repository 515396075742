<template>
  <section class="">

    <v-tabs v-model="active">
      <template v-if="webID && sectionSponsorsConfig.default[webID]">
        <v-tab :ripple="{ class: 'accent--text' }">Web</v-tab>
        <v-tab-item px-0 class="main-body">
          <build-notice mode="full" />
          <sponsor-uploader :sectionID="webID" />
        </v-tab-item>
      </template>

      <template v-if="appID && sectionSponsorsConfig.default[appID]">
        <v-tab :ripple="{ class: 'accent--text' }">App</v-tab>
        <v-tab-item px-0 class="main-body">
          <sponsor-uploader :sectionID="appID" />
        </v-tab-item>
      </template>
    </v-tabs>

    <div class="main-body">
      <p v-if="!sectionSponsorsConfig.default[webID] && !sectionSponsorsConfig.default[appID]">You do not have this sponsor section enabled on app or web</p>
    </div>
  </section>
</template>

<script>
import SponsorUploader from "@/components/Sponsors/Subpages/SectionSponsors/SponsorUploader"
import BuildNotice from "@/components/Library/BuildNotice/BuildNotice"

export default {
  name: "SectionSponsors",

  components: {
    SponsorUploader,
    BuildNotice
  },

  props: {
    appID: {
      type: String
    },
    webID: {
      type: String
    }
  },

  data: () => ({
    sectionSponsorsConfig: null,
  }),

  created() {
    this.sectionSponsorsConfig = require("../../../../config/features/"+process.env.VUE_APP_CLUB_ID+"/sectionSponsors.js")
  }
}
</script>

<style scoped lang="scss">

</style>
