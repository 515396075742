<template>
  <div class="sponsors-container">
    <v-btn
      v-if="!displaySponsors"
      class="sponsors-container__display-sponsors"
      small
      depressed
      :disabled="false"
      @click="displaySponsors = true"
    >
      <v-icon>keyboard_arrow_down</v-icon> Show Sponsors
    </v-btn>

    <v-btn
      v-else
      class="sponsors-row__display-sponsors"
      small
      depressed
      :disabled="false"
      @click="displaySponsors = false"
    >
      <v-icon>keyboard_arrow_up</v-icon> Hide Sponsors
    </v-btn>

    <div v-show="displaySponsors">

      <draggable
        v-model="sponsorsClone"
        @start="drag=true"
        @end="drag=false, sponsorsOrderUpdated=true">
        <transition-group name="list-complete" tag="ol" class="sponsors">
          <li v-for="sponsor in sponsorsClone" :key="`sponsor-${sponsor.sponsorID}`" class="sponsors__item draggable-item" :class="{'sponsors__item--unpublished': !sponsor.published}">
            <p v-if="sponsor.published" class="sponsors__published-label">Published</p>
            <p v-else class="sponsors__published-label">Draft</p>
            <v-switch
              v-model="sponsor.published"
              @change="sponsorsOrderUpdated=true"
            ></v-switch>
            <div class="sponsors__image" :style="`background-image: url(${imageDomain}/${sponsor.imageKey})`"></div>
            <button class="sponsors__edit-button" type="button" @click="loadSponsorData(sponsor)">Edit</button>
          </li>
        </transition-group>
      </draggable>

      <div class="sponsors-container__button-row">
        <v-btn
          class="sponsors-container__add"
          color="accent"
          small
          outline
          flat
          :disabled="false"
          @click="loadBlankSponsor()"
        >
          Add Sponsor
        </v-btn>

        <v-btn
          class="sponsors-container__add"
          color="accent"
          small
          outline
          flat
          :disabled="!sponsorsOrderUpdated"
          @click="updateSponsors()"
        >
          Publish/Reorder Sponsors
        </v-btn>

        <p v-if="sponsorsOrderUpdated" class="sponsors-container__notice">Changes to this row made. Please save before leaving</p>
      </div>

    </div>

    <v-dialog
      class="sponsor-add"
      v-model="dialog"
      width="580"
      scrollable
      lazy>
      <v-card>
        <v-card-title v-if="sponsorData.sponsorID">Edit row - {{ sponsorData.sponsorID }}</v-card-title>
        <v-card-title v-else>Add a new row</v-card-title>

        <v-card-text>
          <div>
            <label class="label" for="admin-name">Admin name (required / internal use)</label>
            <v-text-field
              id="admin-name"
              v-model="sponsorData.adminName"
              solo
              flat
              required
            ></v-text-field>
          </div>

          <div>
            <label class="label" for="title">Title (required / used for alt tags)</label>
            <v-text-field
              id="title"
              v-model="sponsorData.title"
              solo
              flat
              required
            ></v-text-field>
          </div>

          <div>
            <label class="label" for="url">URL (required)</label>
            <v-text-field
              id="url"
              v-model="sponsorData.url"
              solo
              flat
              required
            ></v-text-field>
          </div>

          <ImageUploader :title="'Sponsor image (required)'" :widthOptionEnabled="false" :mediaLibraryID="sponsorData.mediaLibraryID" @update="updateImage($event)" :desc="'<p>Recommended size:</p><p>Large logos: 160px by 160px</p><p>Small logos: 96px by 96px</p>'"/>

          <div>
            <label class="label" for="child-friendly">Is Child Friendly?</label>
            <v-select
              :items="[
                {name: 'True', value: 1},
                {name: 'False', value: 0},
              ]"
              v-model="sponsorData.isChildFriendly"
              item-value="value"
              item-text="name">
            </v-select>
          </div>

          <div>
            <label class="label" for="campaignID">Campaign ID (optional / not in use currently)</label>
            <v-text-field
              id="campaignID"
              v-model="sponsorData.campaignID"
              solo
              flat
              required
            ></v-text-field>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="sponsorData.sponsorID"
            small
            color="error"
            outline
            flat
            :disabled="sponsorData.title.length < 1"
            @click="deleteSponsor(sponsorData.sponsorID)"
          >
            Delete Sponsor
          </v-btn>

          <v-btn
            v-if="!sponsorData.sponsorID"
            color="accent"
            outline
            flat
            :disabled="sponsorData.title.length < 1"
            @click="addSponsor()"
          >
            Add Sponsor
          </v-btn>
          <v-btn
            v-else
            color="accent"
            outline
            flat
            :disabled="sponsorData.title.length < 1"
            @click="editSponsor()"
          >
            Edit Sponsor
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios"
import draggable from "vuedraggable"
import ImageUploader from "@/components/Library/ImageUploader"

export default {
  name: "FooterRowSponsors",

  components: {
    draggable,
    ImageUploader
  },

  props: {
    rowID: {
      type: String,
      required: true
    },
    sponsors: {
      type: Array
    }
  },

  data:() => ({
    imageDomain: process.env.VUE_APP_IMAGEURL,
    api: `${process.env.VUE_APP_SPONSORS_WEB}/footersponsors/rows/sponsors`,
    sponsorsClone: [],
    dialog: false,
    sponsorData: {
      rowID: "",
      adminName: "",
      title: "",
      mediaLibraryID: "",
      imageKey: "",
      url: "",
      isChildFriendly: 1,
      campaignID: "",
    },
    sponsorsOrderUpdated: false,
    displaySponsors: false
  }),

  mounted() {
    this.sponsorsClone = JSON.parse(JSON.stringify(this.sponsors))
    this.resetData()
  },

  methods: {
    resetData() {
      this.sponsorData = {
        rowID: this.rowID,
        adminName: "",
        title: "",
        mediaLibraryID: "",
        imageKey: "",
        url: "",
        isChildFriendly: 1,
        campaignID: "",
      }
    },

    requestFetch() {
      this.$emit("requestUpdate")
    },

    loadBlankSponsor() {
      this.resetData()
      this.dialog = true
    },

    addSponsor() {
      this.$store.commit("startLoading");
      this.status = "saving";
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/addnew`,
          headers: { "Authorization": jwt },
          data: this.sponsorData
        })
        .then(response => {
          console.log("Response:", response);

          if (response.data.success) {
            this.status = "saved";
            this.resetData()
            this.dialog = false
            this.requestFetch()
          } else {
            this.status = "needsSaving"
            alert(response.data.message)
          }
        })
        .catch(e => {
          console.error("Error creating row", e);
          this.$store.commit("completeLoading");
          this.errorMessage = "Problem adding sponsor [error: Sodium]";
        });
      })
    },

    loadSponsorData(sponsorToEdit) {
      this.sponsorData = {
        rowID: this.rowID,
        sponsorID: sponsorToEdit.sponsorID,
        adminName: sponsorToEdit.adminName,
        title: sponsorToEdit.title,
        imageKey: sponsorToEdit.imageKey,
        mediaLibraryID: sponsorToEdit.mediaLibraryID,
        url: sponsorToEdit.url,
        isChildFriendly: sponsorToEdit.isChildFriendly,
        campaignID: sponsorToEdit.campaignID,
      }

      this.dialog = true
    },

    editSponsor() {
      this.$store.commit("startLoading");
      this.status = "saving";
      console.log("editSponsor", this.sponsorData)
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/update`,
          headers: { "Authorization": jwt },
          data: this.sponsorData
        })
        .then(response => {
          console.log("Response:", response);

          if (response.data.success) {
            this.status = "saved";
            this.resetData()
            this.dialog = false
            this.requestFetch()
          } else {
            this.status = "needsSaving"
            alert(response.data.message)
          }
        })
        .catch(e => {
          console.error("Error editing sponsor", e);
          this.$store.commit("completeLoading");
          this.errorMessage = "Problem editing sponsor [error: Sodium]";
        });
      })
    },

    updateImage(payload) {
      console.log("updateImage", payload)
      this.sponsorData.mediaLibraryID = payload.mediaLibraryID
      this.sponsorData.imageKey = payload.imageKey
    },

    updateSponsors() {
      this.status = "saving";
      this.$store.commit("startLoading");

      const updateSponsors = this.sponsorsClone.map((sponsor, index) => {
        return {
          sponsorID: sponsor.sponsorID,
          published: sponsor.published ? 1 : 0,
          forcedOrder: index
        }
      });

      console.log("this.sponsorsClone:", this.sponsorsClone);

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/publish?rowID=${this.rowID}`,
          headers: { "Authorization": jwt },
          data: updateSponsors
        })
        .then(response => {
          console.log("updateSponsors:", response);

          if (response.data.success) {
            this.status = "saved";
            this.requestFetch()
            this.sponsorsOrderUpdated = false
          } else {
            this.status = "needsSaving"
            alert(response.data.message)
          }
        })
        .catch(e => {
          console.error("Error bulk updating sponsors", e);
          this.errorMessage = "Problem updating sponsors [error: Sodium]";
        });
      })

      this.$store.commit("completeLoading");
    },

    deleteSponsor(sponsorID) {
      console.log("sponsorID", sponsorID) 
      const answer = window.confirm("Do you really want to delete this sponsor?")
      if (answer) {
        this.$store.commit("startLoading");
        this.status = "saving";

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios
          .delete(`${this.api}/delete?rowID=${this.rowID}&sponsorID=${sponsorID}`, { "headers": { "Authorization": jwt }})
          .then(response => {
            console.log("Response:", response);

            if (response.data.success) {
              this.status = "saved";
              this.dialog = false
              this.requestFetch()
            } else {
              this.status = "needsSaving"
              alert(response.data.message)
            }
          })
          .catch(e => {
            console.error("Error deleting row", e);
            this.$store.commit("completeLoading");
            this.status = "needsSaving";
            this.errorMessage = "Problem deleting row [error: Sodium]";
          })
        });
      }
    },
  }

}
</script>

<style scoped lang="scss">
  .sponsors-container {
    padding: 8px 0 0;
    border-top: 1px solid #E6E8F1;

    &__button-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 16px 0 0;
    }

    &__notice {
      margin: 0;
      padding: 0;
      font-size: 12px;
    }
  }

  .sponsors {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 8px;
      padding: 4px 8px;
      background-color: #ebebeb;
      border: 1px solid #ccc;
      opacity: 1;

      &--unpublished {
        opacity: 0.6;
      }
    }

    &__published-label {
      margin: 0;
      font-size: 10px;
    }

    &__image {
      height: 100px;
      width: 100px;
      background-size: contain;
      background-position: center;
      border: 1px dashed #fff;
    }

    &__edit-button {
      display: inline-block;
      margin: 8px 0 0;
      padding: 2px 4px 0;
      font-size: 10px;
      color: #67247c;
      border: 1px solid;
      border-radius: 2px;
    }
  }

  .v-input--selection-controls {
    margin-top: 4px !important;
  }

  .v-input--selection-controls .label {
    font-size: 10px !important;
    margin: 0 !important;
  }

  .list-complete-enter, .list-complete-leave-to {
    opacity: 0;
  }

  .list-complete-leave-active {
    position: absolute;
    width: calc(100% - (var(--body-gutter) * 2));
  }

  .draggable-item {
    transition: all 0.5s, opacity 0.2s;
  }
</style>
